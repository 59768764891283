import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KnowledgeService } from './../../services/knowledge.service';

@Component({
  selector: 'app-knowledge-center',
  templateUrl: './knowledge-center.component.html',
  styleUrls: ['./knowledge-center.component.scss'],
})
export class KnowledgeCenterComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  tabId: any = 1;
  spinner: boolean;
  knowledgeDetails: any;
  content: any;

  constructor(
    public toastController: ToastController,
    private service: KnowledgeService,
    private route: ActivatedRoute,
    public loadingController: LoadingController
  ) {}

  async ionViewDidEnter() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      
    });
    await loading.present();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(({ id }) => {
      this.service
        .getKnowledgeDetails(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (_: any) => {
            this.loadingController.dismiss();
            this.knowledgeDetails = _.data[0];
            this.content = this.knowledgeDetails?.overview;
            //  this.knowledgeList.
            this.spinner = false;
          },
          (err) => {
            this.failToast(err.error.message);
            this.loadingController.dismiss();
            this.spinner = false;
          }
        );
    });
  }

  async tostSuccess(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }

  async failToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: 'danger',
    });
    toast.present();
  }

  ngOnInit() {
  
  }

  selectedTab(value) {
    switch (value) {
      case 1:
        this.tabId = value;
        this.content = this.knowledgeDetails.overview;

        break;
      case 2:
        this.tabId = value;
        this.content = this.knowledgeDetails.symptoms;
        break;
      case 3:
        this.tabId = value;
        this.content = this.knowledgeDetails.risk_factors;
        break;
      case 4:
        this.tabId = value;
        this.content = this.knowledgeDetails.diagnosis;
        break;

      default:
        this.content = this.knowledgeDetails.overview;
        break;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
