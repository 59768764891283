import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  name="";
  image: string="";

  constructor(
    private router: Router,
    private service: AuthService) { }

  ngOnInit() {
    
    //let nameKey=btoa("nirnayanb2b_company_name");
    //if(localStorage.getItem(nameKey))  this.name=atob(localStorage.getItem(nameKey));
    this.name=localStorage.getItem("name");
  
  }

  redirect(path) {
    this.router.navigate([path]);
  }
  redirectProfile(path) {
    this.router.navigate([path]);
  }
  openepfo =  async () => {
    window.open("https://unifiedportal-mem.epfindia.gov.in/memberinterface/","_system");  
   };


}
