import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { SharedModule } from './shared/shared.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
} from '@angular/common/http';
import { HTTPInterceptor } from 'src/http-interceptor';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { Camera } from '@ionic-native/camera/ngx';

import { environment } from './../environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FilterPipe } from './services/filter';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
  BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicSelectableModule,
    SharedModule,
 
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
  ],
  providers: [
    // Camera,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptor,
      multi: true,
    },
  ],

  exports: [SharedModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
