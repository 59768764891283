import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeService {

  constructor(private http: HttpClient) { }

  getKnowledgeList() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getKnowledgeList));
  }

  getKnowledgeDetails(id: any) {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getKnowledgeDetails + `/${id}`));
  }
}
