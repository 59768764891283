import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) { }
  async showLoading() {
  const loading = await this.loadingCtrl.create({
      message: 'Please Wait...', 
      backdropDismiss:true
    });

 await loading.present();
 return loading
   
  }
 async hideLoading(){
   await this.loadingCtrl.dismiss()
    this.loading.dismiss();
  }

  async dismissAllLoaders() {
   
    let topLoader = await this.loadingCtrl.getTop();
    console.log(topLoader)
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        await this.loadingCtrl.dismiss()
        this.loading?.dismiss();
        console.log("error")
        throw new Error('Could not dismiss the topmost loader. Aborting...');
      }
      topLoader = await this.loadingCtrl.getTop();
    }
  }
}
