import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platform: Platform,
    public router: Router
  ) {
    
    this.initializeApp();
    //StatusBar.setOverlaysWebView({ overlay: false });
    const setStatusBarStyleLight = async () => {
     
      await StatusBar.setStyle({ style: Style.Dark });
    };
    setStatusBarStyleLight()
    StatusBar.show();

  }

  initializeApp() {
    // this.platform.ready().then(() => {
    //   // StatusBar.hide();
    //   this.router.navigateByUrl('/splash');
    // });
    StatusBar.setStyle({ style: Style.Dark });
  }


  
 

}
