import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  image: any="";
  name:any='';
  mediaURL:any='';
  imageurl:any='';

  constructor(
    private router: Router,
    private service: AuthService
    ) { 
 
       this.mediaURL = environment.apiUrls.mediaURL;
       this.name=localStorage.getItem('name') 
       this.image='user.png'
       this.image=localStorage.getItem('image') 
       debugger
       if(this.image==null)
       {
        this.image='user.png'
       }
       this.imageurl=this.mediaURL+this.image
       debugger
    }

  ngOnInit() { 
    this.mediaURL = environment.apiUrls.mediaURL;
  console.log('Media URL:', this.mediaURL);

  this.name = localStorage.getItem('name');
  
  const storedImage = localStorage.getItem('image') || 'user.png';
  console.log('Stored Image:', storedImage);

  this.image = storedImage ? storedImage : 'user.png';
  this.imageurl = `${this.mediaURL}${this.image}`;

  console.log('Image URL:', this.imageurl);
  }
  redirect(path) {
    this.router.navigate([path]);

  }

}
