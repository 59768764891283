import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: any = '';
  mediaURL: any = '';

  constructor(
    private http: HttpClient) 
  { 
    this.baseUrl = environment.apiUrls.baseUrl;
    this.mediaURL = environment.apiUrls.mediaURL;
  }

  login(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.Login),payload);
  }

  editEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.editEmployee),payload);
  }

  getEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getEmployee),payload);
  }

  getByParameterLike(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getByParameterLike),payload);
  }
  getAllParameter() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllParameter));
  }

  getAllLeave() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllLeave));
  }
  saveLeave(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.saveLeave),payload);
  }

  getAllAttendance() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllAttendance));
  }
  getAttendanceData() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAttendanceData));
  }
  saveAttendance(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.saveAttendance),payload);
  }
  updateAttendance(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.updateAttendance),payload);
  }
  updateAttendancefromapp(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.updateAttendancefromapp),payload);
  }
  checkintime(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.checkintime),payload);
  }
  checkouttime(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.checkouttime),payload);
  }
  getAttendanceDataByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAttendanceDataByEmployee),payload);
  }

  getleavesbyemployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getleavesbyemployee),payload);
  }


  getleavesbyemployeereport(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getleavesbyemployeereport),payload);
  }




  getDistinctMonthByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getDistinctMonthByEmployee),payload);
  }
  getDistinctYearByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getDistinctYearByEmployee),payload);
  }
  getSalaryByMonth(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getSalaryByMonth),payload);
  }
  getSalaryByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getSalaryByEmployee),payload);
  }

  getTasksByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getTasksByEmployee),payload);
  }
  getTasksById(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getTasksById),payload);
  }
  editTask(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.editTask),payload);
  }


  getComplainByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getComplainByEmployee),payload);
  }
  getComplainById(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getComplainById),payload);
  }
  saveComplain(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.saveComplain),payload);
  }

  getSuggestionByEmployee(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getSuggestionByEmployee),payload);
  }
  getSuggestionById(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getSuggestionById),payload);
  }
  saveSuggestion(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.saveSuggestion),payload);
  }

  getAllPolicy() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllPolicy));
  }
  getPolicyById(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getPolicyById),payload);
  }

  getAllNotice() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllNotice));
  }
  getNoticeById(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getNoticeById),payload);
  }

  getAllAdvance() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllAdvance));
  }

  getWorkingDaysOfMonth(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getWorkingDaysOfMonth),payload);
  }
  getWorkingDaysOfYear(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getWorkingDaysOfYear),payload);
  }


  getMonthAttendanceReport(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getMonthAttendanceReport),payload);
  }
  getYearAttendanceReport(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getYearAttendanceReport),payload);
  }

  getAllHolidays() {
    return this.http.get((environment.apiUrls.baseUrl + environment.apiUrls.apis.getAllHolidays));
  }
  getPresentDays(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getPresentDays),payload);
  }


  getNetEarning(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getNetEarning),payload);
  }
  getNetDeduction(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getNetDeduction),payload);
  }


  getlocationbyname(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getlocationbyname),payload);
  }

  getLocation(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.getLocation),payload);
  }


  uploadImage(files: File[]) {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('userfile', f));
    return this.http.post(environment.apiUrls.baseUrl + 'employee/upload_image', formData);
  }


  saveattendancefromapp(payload:any) {
    return this.http.post((environment.apiUrls.baseUrl + environment.apiUrls.apis.saveattendancefromapp),payload);
  }


}
