import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable, throwError, } from 'rxjs';
import { catchError, finalize,tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
    private loaderControllers = [];
    constructor(public router: Router,private loaderService:LoaderService) {}

    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
    //    if(this.loaderControllers.length==0){
        const loader = this.loaderService.showLoading();
        this.loaderControllers.push(loader);
    //    } 
        
      console.log("start")
      return next.handle(req).pipe(
        
        catchError(error => {
         console.log("stop")
          console.error("error is intercept", error);
          return throwError(error.message);
        }),
        finalize(() => {
           
            console.log("stop")
           this.loaderService.dismissAllLoaders()
        }),
      );
    }

    // intercept(request: HttpRequest<any>, next: HttpHandler) {
    //     this.loaderService.showLoading();
    //     this.loaderControllers.push(loader);
    //     console.log("start");
    //     return next.handle(request).pipe(
    //       tap(
    //         req => {
    //           if (req instanceof HttpResponse) {
    //             this.loaderService.dismissAllLoaders();
    //             console.log("end");
    //           }
    //         },
    //         err => {
    //           this.loaderService.dismissAllLoaders();
    //           console.log("end");
    //         }
    //       )
    //     );
    // }
}
















// import { Injectable } from '@angular/core'; import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http'; import { Observable, throwError } from 'rxjs'; import { catchError, finalize } from 'rxjs/operators'; import { Router } from '@angular/router'; import { LoaderService } from 'src/app/services/loader.service'; import { LoadingController } from '@ionic/angular'; @Injectable() export class HTTPInterceptor implements HttpInterceptor { constructor(public router: Router, private loadingCtrl: LoadingController) {} intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> { this.loadingCtrl.getTop().then(hasLoading=>{ if(!hasLoading){ this.loadingCtrl.create({ message: 'Please Wait...', }).then(loading=>loading.present()); } }) console.log("start") return next.handle(req).pipe( catchError(error => { console.log("stop") console.error("error is intercept", error); return throwError(error.message); }), finalize(() => { console.log("stop"); this.loadingCtrl.getTop().then(hasLoading=>{ if(hasLoading){ this.loadingCtrl.dismiss(); } }) }) ); } }